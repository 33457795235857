export default function () {

	// toggle checkbox/
	$('.form-checks[data-limit]:not(.form-checks-done)').each(function () {
		var wrapper = $(this);
		var limit = parseInt(wrapper.attr('data-limit'), 10);
		var itemsToShow = parseInt(wrapper.attr('data-limit-show'), 10);
		var items = wrapper.find('.form-checks__item');

		if (items.length > limit) {
			// hide over limit items
			items.each(function (i) {
				if (i >= itemsToShow) {
					$(this).hide();
				}
			});

			// open flag
			wrapper.attr('data-limit-open', false);

			// add more toggle link
			wrapper.after('<p class="m-search-page__filter__more-link"><a href="javascript:void(0)" class="m-search-page__filter__more-link__inner" data-limit-toggle>+ show more options</a></p>');

			// functionality
			wrapper.next('.m-search-page__filter__more-link').find('.m-search-page__filter__more-link__inner').on('click', function () {

				var link = $(this);

				if (link.hasClass('less')) {
					// hide over limit items
					items.each(function (i) {
						if (i >= itemsToShow) {
							$(this).hide();
						}
					});
					// change text
					link.text('+ show more options');
					// remove class
					link.removeClass('less');
					// open flag
					wrapper.attr('data-limit-open', false);
				}

				else {
					// show over limit items
					items.show();
					// change text
					link.text('- show less options');
					// add class
					link.addClass('less');
					// open flag
					wrapper.attr('data-limit-open', true);
				}

				return false;
			});

			// done
			wrapper.addClass('form-checks-done');
		}
	});
}
